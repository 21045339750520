import { useEffect } from "react";

export default function Changelog(props) {

    const { buildDate, handleSetLastViewedChangelog } = props;

    localStorage.setItem("LastViewedChangelog", buildDate);
    useEffect(() => {
        handleSetLastViewedChangelog(buildDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    const badge = {
        new: {
            text: 'New',
            tag: "new",
            badge: "bg-success"
        },
        fixed: {
            text: "Fixed",
            tag: "fixed",
            badge: "bg-secondary"
        },
        improved: {
            text: "Improved",
            tag: "improved",
            badge: "bg-info"
        }
    }
    const RenderBadge = (props) => {
        return (<p className={"badge " + props.badge.badge + " " + props.badge.tag}>{props.badge.text}</p>)
    }
    return (
        <div className='container pt-4'>
            <a href="/"><i className='bx bx-undo'></i>Back to Pipeline</a>
            {/* 
        Emmit shortcut for the below 
        div.release.mx-auto>h3{DATE}+ul>RenderBadge[badge={badge.improved}]+li{Text for item} 
    */}

        <div className="release mx-auto">
            <h3>October 15th, 2024</h3>
            <ul>
                <RenderBadge badge={badge.new}></RenderBadge>
                <li>When a customer users a promotional code, that code will be shown in Pipeline below their rate. Hovering over the ❓ icon will show any instructions that existed when the promotional code was live.</li>
            </ul>
        </div>
        <div className="release mx-auto">
            <h3>May 15th, 2024</h3>
            <ul>
                <RenderBadge badge={badge.fixed}></RenderBadge>
                <li>
                    When installing the app as a Progressive Web App (by clicking install in the search bar at the sign in screen) Pipeline was using the default app name and icon.<br />
                    Now Pipeline is Pipeline. Accept no substitutes.
                </li>
            </ul>
        </div>
    

            <div className="release mx-auto">
                <h3>March 7th, 2024</h3>
                <ul>
                    <RenderBadge badge={badge.new}></RenderBadge>
                    <li>
                        Added "Requested Rate Amount" field which shows the amount the customer clicked on for a renewal.<br />
                        <small>The customer is not necessarily elligible for this amount</small>
                    </li>
                    <RenderBadge badge={badge.improved}></RenderBadge>
                    <li>
                        Fixed repeating gradient when using "Home Field Advantage" theme
                    </li>

                </ul>
            </div>
            <div className="release mx-auto">
                <h3>May 17th, 2023</h3>
                <ul>
                    <RenderBadge badge={badge.new}></RenderBadge>
                    <li>Added "Created By" field, when a CSR submits a form on behalf of a customer</li>
                </ul>
            </div>
            <div className="release mx-auto">
                <h3>March 14th, 2023</h3>
                <ul>
                    <RenderBadge badge={badge.new}></RenderBadge>
                    <li>Add "What's New" aka Changelog</li>
                </ul>
            </div>
            <div className="release mx-auto">
                <h3>March 8th, 2022</h3>
                <ul>
                    <RenderBadge badge={badge.new}></RenderBadge>
                    <li>Add Rate Plan number</li>
                    <li>
                        Add market source, if avaliable
                        <ul>
                            <li>Answer to the question "How did you hear about us?</li>
                        </ul>
                    </li>
                    <RenderBadge badge={badge.improved}></RenderBadge>
                    <li>Add error message to popup if the connection fails</li>

                </ul>
            </div>
            <div className="release mx-auto">
                <h3>February 22nd, 2023</h3>
                <ul>
                    <RenderBadge badge={badge.new}></RenderBadge>
                    <li>Progress bar for the initail list download</li>
                    <RenderBadge badge={badge.fixed}></RenderBadge>
                    <li>Adding new users would sometimes crash the site</li>
                </ul>
            </div>
            <div className="release mx-auto">
                <h3>February 8th, 2023</h3>
                <ul>
                    <RenderBadge badge={badge.improved} />
                    <li>Sort <mark>Assign to</mark> list alphabetically by first name</li>
                </ul>
            </div>
            <div className="release mx-auto">
                <h3>February 5th, 2023</h3>
                <ul>
                    <RenderBadge badge={badge.improved} />
                    <li>
                        Add popup error message when attempting to send an unfinished email message
                        <ul>
                            <li>Unfinished email is when a _PLACEHOLDER_TAG_ is still present</li>
                            <li>Or if it's blank</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className='release mx-auto'>
                <h3>Jan 21, 2023</h3>
                <ul>
                    <RenderBadge badge={badge.new} />
                    <li>
                        Add print button <span className="tf-icons bx bx-printer"></span></li>
                </ul>
            </div>
            <p>Build date: {buildDate}</p>

        </div>
    );
}