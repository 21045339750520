/* global $ */
import React, { useEffect } from "react";

import usePromise from "react-promise";
import {FindStatus, TimestampFormat, AddressFormat, RatePlans, ChannelTypes, MarketSources, InquiryTypes, DecryptSSN, FindPromoCode } from "../Utils";

const CopyToClipboard = (e, data=null) => {
    const copyText = data? data : e.target.innerText;
    navigator.clipboard.writeText(copyText);
}

const ResolvePromise = (data) => {
    const {value, loading} = usePromise(data);
    if (loading){
        return(<>Loading <i className='bx bx-loader-alt bx-spin'></i></>);
    }
    return value;
}

const PropertyRow = (props) => {
    const {title, data="", debug=false} = props;
    const debugClass = debug? "debug-only": "";
    //if data is a promise
    if (data instanceof Promise){
        return(
            <tr className={debugClass}>
                <td><strong>{title}</strong></td>
                <td className="copy-cell" onClick={CopyToClipboard}>{ResolvePromise(data)}<span className="copy-icon bx  bx-copy"></span></td>
            </tr>
        )
    }
    if (data){
        return(
            <tr className={debugClass}>
                <td><strong>{title}</strong></td>
                <td className="copy-cell" onClick={CopyToClipboard}>{data}<span className="copy-icon bx  bx-copy"></span></td>
            </tr>
        )
    }
}

const PromoPropertyRow = (props) => {
    const {title, promoId=0, data="", debug=false} = props;
    const {value, loading} = usePromise(data);
    const debugClass = debug? "debug-only": "";
    if (promoId !== 0 && promoId !== null){  
        if (loading){
            return(
                <tr className={debugClass}>
                    <td><strong>{title}</strong></td>
                    <td className="copy-cell" onClick={CopyToClipboard}>Loading Promo Code #{promoId} <i className='bx bx-loader-alt bx-spin'></i></td>
                </tr>
            )
        }
        if (value !== ""){
            //Run javascript to occure a few milliseconds after the component is rendered
            setTimeout(() => {
                $('[data-popup="tooltip-custom"]').tooltip();
            }, 100);
            return(
                <tr className={debugClass}>
                    <td><strong>{title}</strong></td>
                    <td className="copy-cell" onClick={CopyToClipboard}>{value.PromoCode ? value.PromoCode : "(No Code)"} 
                        <span data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="right" title={value.Description ? value.Description : "(No Description Provided)"} className="promo-question">
                            <i className='bx bx-question-mark bx-border-circle'></i>
                        </span>
                        
                    </td>
                </tr>
            )
        }
    }
}

const SensitivePropertyRow = (props) => {
    const {title, data="", fallback="", debug=false} = props;
    const debugClass = debug? "debug-only": "";
    if (data){
        return(
            <tr className={debugClass}>
                <td><strong>{title}</strong></td>
                <td className="copy-cell" onClick={(e) => CopyToClipboard(e, data)} data-sensitive={data}>&bull;&bull;&bull;&bull;-&bull;&bull;-{data.substr(data.length - 4)}<span className="copy-icon bx  bx-copy"></span></td>
            </tr>
        )
    } else if (data === false) {
        return (
            <tr className={debugClass}>
                <td><strong>{title} (No decryption key)</strong></td>
                <td onClick={(e) => CopyToClipboard(e, fallback)} data-sensitive={fallback}>{fallback}</td>
            </tr>
        )
    } else if (data === "" && fallback){
        return (
            <tr className={debugClass}>
                <td><strong>{title}</strong></td>
                <td onClick={(e) => CopyToClipboard(e, fallback)} data-sensitive={fallback}>{fallback}</td>
            </tr>
        )
    }
}
const GetInquiryDetailsList = (props) => {
    
    const {inquiry} = props;
    const keys = Object.keys(inquiry);

    useEffect(() => {
    }, [inquiry]);
    
    return(
        <table className="table table-sm table-striped table-hover">
                    <tbody>
                        <PropertyRow title={"Internal Id"} data={inquiry.InquiryId} />
                        <PropertyRow title={"Status"} data={FindStatus(inquiry)} />
                        <PropertyRow title={"Created"} data={TimestampFormat(inquiry.CreatedOn)} />
                        <PropertyRow title={"Last Updated"} data={TimestampFormat(inquiry.LastUpdatedOn)} />
                        <PropertyRow title={"Service Street Address"} data={inquiry.ServiceAddressLine1} />
                        <PropertyRow title={"Service Street Address 2"} data={inquiry.ServiceAddressLine2} />
                        <PropertyRow title={"Service City"} data={inquiry.ServiceCity} />
                        <PropertyRow title={"Service State"} data={inquiry.ServiceState} />
                        <PropertyRow title={"Service Zip Code"} data={inquiry.ServiceZip} />
                        <PropertyRow title={"Service Address"} data={AddressFormat(inquiry.ServiceAddressLine1, inquiry.ServiceAddressLine2, inquiry.ServiceCity, inquiry.ServiceState, inquiry.ServiceZip)} />
                        <SensitivePropertyRow title={"SSN"} data={ DecryptSSN(inquiry.EncryptedSSN)} fallback={inquiry.SSN4} />
                        <PropertyRow title={"Rate Plan"} data={RatePlans[inquiry.RatePlanId]? RatePlans[inquiry.RatePlanId] : "Rate Plan #" + inquiry.RatePlanId} />
                        <PropertyRow title={"Rate Plan Amount"} data={inquiry.RatePlanNumber ? "$" + inquiry.RatePlanNumber : ""} />
                        <PropertyRow title={"Requested Rate Amount"} data={inquiry.RequestedRate && inquiry.RequestedRate !== inquiry.RatePlanNumber ? "$" + inquiry.RequestedRate : ""} />
                        <PromoPropertyRow title={"Promo Code"} promoId={inquiry.PromoCodeId} data={FindPromoCode(inquiry.PromoCodeId)} />
                        <PropertyRow title={"Disconnect On"} data={TimestampFormat(inquiry.DisconnectOn, "day")} />
                        <PropertyRow title={"Connect On"} data={TimestampFormat(inquiry.ConnectionOn, "day")} />
                        <PropertyRow title={"Current Electricity Provider"} data={inquiry.CurrentElectricityProvider} />
                        <PropertyRow title={"Current Natural Gas Marketer"} data={inquiry.CurrentNaturalGasMarketer} />
                        <PropertyRow title={"Business Name"} data={inquiry.BusinessName} />
                        <PropertyRow title={"Contact First Name"} data={inquiry.FirstName} />
                        <PropertyRow title={"Contact Middle Name"} data={inquiry.MiddleName} />
                        <PropertyRow title={"Contact Last Name"} data={inquiry.LastName} />
                        <PropertyRow title={"Contact Home Phone Number"} data={inquiry.PhoneHome} />
                        <PropertyRow title={"Contact Cell Number"} data={inquiry.PhoneMobile} />
                        <PropertyRow title={"Contact Office Number"} data={inquiry.PhoneOffice} />
                        <PropertyRow title={"Contact Email Address"} data={inquiry.EmailAddress} />
                        <PropertyRow title={"Contact Street"} data={inquiry.ContactAddressLine1} />
                        <PropertyRow title={"Contact Street 2"} data={inquiry.ContactAddressLine2} />
                        <PropertyRow title={"Contact City"} data={inquiry.ContactCity} />
                        <PropertyRow title={"Contact State"} data={inquiry.ContactState} />
                        <PropertyRow title={"Contact Zip Code"} data={inquiry.ContactZip} />
                        <PropertyRow title={"Account Number"} data={inquiry.AccountNumber} />
                        <PropertyRow title={"AGLC Account Number"} data={inquiry.AglcAccountNumber} />
                        <PropertyRow title={"Account First Name"} data={inquiry.AccountFirstName} />
                        <PropertyRow title={"Account Middle Name"} data={inquiry.AccountMiddleName} />
                        <PropertyRow title={"Account Last Name"} data={inquiry.AccountLastName} />
                        <PropertyRow title={"Entrance Instructions"} data={inquiry.EntranceInstructions} />
                        <PropertyRow title={"Special Instructions"} data={inquiry.SpecialInstructions} />
                        <PropertyRow title={"Billing Street Address"} data={inquiry.BillingAddressLine1} />
                        <PropertyRow title={"Billing Street Address 2"} data={inquiry.BillingAddressLine2} />
                        <PropertyRow title={"Billing City"} data={inquiry.BillingCity} />
                        <PropertyRow title={"Billing State"} data={inquiry.BillingState} />
                        <PropertyRow title={"Billing Zip Code"} data={inquiry.BillingZip} />
                        <PropertyRow title={"Category"} data={InquiryTypes[inquiry.InquiryTypeId]} />
                        <PropertyRow title={"Channel"} data={ChannelTypes[inquiry.ChannelId]} />
                        <PropertyRow title={"Market Source"} data={MarketSources[inquiry.Source]} />
                        <PropertyRow title={"Question/Comments"} data={inquiry.Message} />
                        <PropertyRow title={"Contact Notified On"} data={TimestampFormat(inquiry.ContactNotifiedOn)} />
                        <PropertyRow title={"Admin Notified On"} data={TimestampFormat(inquiry.AdminNotifiedOn)} />
                        <PropertyRow title={"Processed On"} data={TimestampFormat(inquiry.ProcessedOn)} />
                        <PropertyRow title={"Processed By"} data={inquiry.ProcessedBy} />
                        <PropertyRow title={"Created By"} data={inquiry.CreatedBy} />
                        <PropertyRow title={"Utility.org"} data={inquiry.IsUtilitySubmitted ? "Submitted" : ""} />
                        
                        <tr className="debug-only">
                            <td colSpan={2}><strong>Start Automatic Generated Content</strong></td>
                        </tr>
                        {keys.map((key, index) =>(
                            <PropertyRow key={index} debug={true} title={key} data={inquiry[key] != null ? inquiry[key].toString() : ""} />
                        ))}
                    </tbody>
                </table>
    )
}

export default GetInquiryDetailsList;